<template>
    <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" height="100vh" permanent>
        <v-list-item class="px-2">
            <v-list-item-avatar>
                <v-img src="../assets/profile.png"></v-img>
            </v-list-item-avatar>
            <v-list-item-title>Richard Martin</v-list-item-title>
            <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
        </v-list-item>
        <v-divider></v-divider>
         
        <v-card class="ma-2 pa-2 card-side body-2" elevation="2" v-if="mini === false">
            <div>{{ $t('sidebar.dayOfWeek') }} - {{ dayInfo[0] }}</div>
            <div>{{ $t('sidebar.currentDate') }} - {{ dayInfo[1] }} {{ dayInfo[2] }}</div>
        </v-card>

        <v-card class="ma-2 pa-2 card-side body-2" elevation="2" v-if="mini === false">
            <div>{{ $t('sidebar.institutionName') }} - ABC</div>
            <div>{{ $t('sidebar.classGroupName') }} - XYZ</div>
        </v-card>
        <v-divider></v-divider>
        <v-card class="ma-2 pa-4 body-2" elevation="2" v-if="mini === false">
            <div>{{ $t('sidebar.totalStudents') }} - {{ students.length }}</div>
        </v-card>
        <v-card class="ma-2 pa-4 body-2" elevation="2" v-if="mini === false">
            <div>{{ $t('sidebar.presentStudents') }} - {{ presentStudents.length }}</div>
        </v-card>
        <v-card class="ma-2 pa-4 body-2" elevation="2" v-if="mini === false">
            <div>{{ $t('sidebar.absentStudents') }} - {{ absentStudents.length }}</div>
        </v-card>
        <v-card class="ma-2 pa-4 body-2" elevation="2" v-if="mini === false">
            <div>{{ $t('sidebar.unwellStudents') }} - {{ illStudents.length }}</div>
        </v-card>
        <v-card class="ma-2 pa-4 body-2" elevation="2" v-if="mini === false">
            <div>{{ $t('sidebar.unExcusedStudents') }} - {{ unExcusedStudents.length }}</div>
        </v-card>
        <v-card class="ma-2 pa-4 body-2" elevation="2" v-if="mini === false">
            <div>{{ $t('sidebar.loggedOutStudents') }} - {{ loggedOutStudents.length }}</div>
        </v-card>
        <div  class="ma-2 pa-4 body-2 d-flex justify-end">
            
        </div>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import Dropdown from "../components/Languagedropdown"

export default {
    components: { Dropdown },
    data: () => ({
        drawer: true,
        items: [
          { title: 'Home', icon: 'class', isLink: false },
          { title: 'My Account', icon: 'mdi-account', isLink: false  },
          { title: 'Users', icon: 'mdi-account-group-outline', isLink: true  },
        ],
        mini: true,
    }),
    computed: {
        ...mapGetters({
            students: 'dashboardOnline/getStudents',
            presentStudents : 'dashboardOnline/presentStudents',
            absentStudents: 'dashboardOnline/absentStudents',
            illStudents: 'dashboardOnline/illStudents',
            unExcusedStudents: 'dashboardOnline/unExcusedStudents',
            loggedOutStudents: 'dashboardOnline/loggedOutStudents',
            dayInfo: 'dashboardOnline/dayInfo',
        })
    }
}
</script>

<style scoped>
    .card-side > div {
        margin-bottom: 10px
    }
</style>