<template>
    <v-row>
        <!-- First Column -->
        <v-col lg="3" md="3" sm="12" xs="12" class="pa-0 d-xs-flex d-sm-flex d-md-block justify-space-between d-lg-block">
            <usercard/>

            <!-- EDIITS -->
            <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-3 mt-3 mb-3 d-flex justify-space-between flex-wrap">
                <v-card elevation="3" class="pa-2 d-flex justify-center flex-column align-center"  style="width: 100%">
                    <h5>{{ $t('dashboard.present') }}</h5>
                    <h1 class="text-center green--text">{{ presentStudents.length }} </h1>
                </v-card>
                <v-card elevation="3" class="pa-2 mt-2 d-flex justify-center flex-column align-center" style="width: 48%">
                    <h5>{{ $t('dashboard.ill') }}</h5>
                    <h1 class="text-center orange--text">{{ illStudents.length }} </h1>
                </v-card>
                 <v-card elevation="3" class="pa-2 mt-2 d-flex justify-center flex-column align-center"  style="width: 48%">
                    <h5>{{ $t('dashboard.unExcused') }}</h5>
                    <h1 class="text-center blue--text">{{ unExcusedStudents.length }} </h1>
                </v-card>
                <v-card elevation="3" class="pa-2 mt-2 d-flex justify-center flex-column align-center"  style="width: 100%">
                    <h5>{{ $t('dashboard.absent') }}</h5>
                    <h1 class="text-center red--text">{{ absentStudents.length }} </h1>
                </v-card>
                <v-card elevation="3" class="pa-2 mt-2 d-flex justify-center flex-column align-center"  style="width: 100%">
                    <h5>{{ $t('dashboard.loggedOut') }}</h5>
                    <h1 class="text-center purple--text">{{ loggedOutStudents.length }} </h1>
                </v-card>
            </div>
            <!-- NEW EDITTS -->

            <informationcard v-if="$vuetify.breakpoint.smAndUp" class="d-sm-flex d-md-none d-lg-none"/>
            <donut v-if="$vuetify.breakpoint.smAndUp" class="d-sm-none d-md-block d-lg-block"/>
            <classinfo class="d-sm-none d-md-flex d-lg-flex"/>
        </v-col>
        <!-- First Column -->

        <!-- Second Column -->
        <v-col class="mb-12" lg="9" md="9" sm="12" xs="12" >
            <v-card class="d-flex align-center justify-space-between pa-3 elevation-2 mb-2">
                <div class="d-flex" :style="{ width: $vuetify.breakpoint.xs ? '100%' : '30%' }">
                   <div v-if="tableSearching" class="d-flex align-center">
                       <v-progress-circular class="mr-2" :width="2" :size="20" color="blue" indeterminate></v-progress-circular>
                   </div>
                   <v-text-field 
                        class="ma-0 pt-1" 
                        @keydown.space.prevent 
                        @input="debounceInput" 
                        append-icon="mdi-magnify" 
                        :label="$t('dashboard.search')" 
                        single-line hide-details
                    ></v-text-field>
                </div>
                <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex align-center">
                    <v-select
                        v-model="show"
                        small-chips
                        :items="menu"
                        :menu-props="{ maxHeight: '400' }"
                        multiple
                        :label="$t('dashboard.showMssg')"
                        :persistent-hint="false"
                        hide-details
                    ></v-select>
                </div>
            </v-card>
            <v-data-table :loading="isLoading" :loading-text="tableLoadingText" :fixed-header="true" class="elevation-3 pa-0" :headers="selectedHeaders" :items="students" :disable-items-per-page="true" :items-per-page="-1"  :search="search">
                <!-- NAME -->
                <template v-slot:header.name="{ header }">
                {{ $t('dashboard.name') }}
                </template>
                <template v-slot:item.name="{ item }">
                    {{ item.name}}
                </template>
                <!-- NAME -->

                <!-- LAST NAME -->
                <!-- <template v-slot:header.lastname="{ header }">
                {{ $t('dashboard.lastname') }}
                </template> -->
                <!-- LAST NAME -->
                
                <!-- AGE -->
                <template v-slot:header.age="{ header }">
                {{ $t('dashboard.age') }}
                </template>
                <template v-slot:item.age="{ item }">
                    {{ item.age }}
                </template>
                <!-- AGE -->

                <!-- GENDER -->
                <template  v-slot:header.gender=" { header } ">
                {{ $t('dashboard.gender') }}
                </template>
                <template v-slot:item.gender="{ item }">
                    <v-tooltip :color="item.gender === 'male' ? '#90CAF9' : '#FF80AB'" left> 
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon medium v-bind="attrs" v-on="on" :color="item.gender === 'male' ? '#90CAF9' : '#FF80AB'">{{ item.gender === 'male' ? 'fa fa-male' : 'fa fa-female' }}</v-icon>
                        </template>
                        <span>{{ item.gender === 'male' ? $t('dashboard.male') : $t('dashboard.female')}}</span>
                    </v-tooltip>
                </template>
                <!-- GENDER -->

                <!-- LOGGED IN -->
                <template  v-slot:header.loggedIn="{ header }">
                {{ $t('dashboard.logIn') }}
                </template>
                <template  v-slot:item.loggedIn="{ item }">
                    <div v-if="item.attype === 'c' || item.endtime !== null" class="green--text font-weight-bold">
                        <div v-if="item.attype === 'c'">{{ `${item.begtime.hour}:${item.begtime.minute}` }}</div>
                        <v-btn v-else x-small :disabled="true"> {{ $t('dashboard.in') }} </v-btn>
                    </div>
                    <div v-else>
                        <v-btn :loading="item.loggingIn" x-small :disabled="item.attype === 'i' || item.attype === 'u'" color="success" @click="handleStudentAction(item, 'ev_coming', 'login', 'loggingIn')"> {{ $t('dashboard.in') }} </v-btn>
                    </div>
                </template>
                <!-- LOGGED IN -->

                <!-- LOGGED OUT -->
                <template v-slot:header.loggedOut="{ header }">
                {{ $t('dashboard.logOut') }}
                </template>
                <template v-slot:item.loggedOut="{ item }">
                    <div v-if="item.endtime !== null" class="red--text font-weight-bold">{{  `${item.endtime.hour}:${item.endtime.minute}` }}</div>
                    <div v-else>
                        <v-btn x-small :loading="item.loggingOut" :disabled="item.attype === null || item.attype === 'i' || item.attype === 'u'" color="error" @click="handleStudentAction(item, 'ev_going', 'logout', 'loggingOut')">{{ $t('dashboard.out') }}</v-btn>
                    </div>
                </template>
                <!-- LOGGED OUT -->

                <!-- ILL -->
                <template v-slot:header.ill="{ header }">
                {{ $t('dashboard.ill') }}
                </template>
                <template v-slot:item.ill="{ item }">
                    <div v-if="item.attype === 'i'" class="warning--text font-weight-bold">{{ $t('dashboard.isIll') }}</div>
                    <div v-else>
                        <v-btn x-small :loading="item.isIll" :disabled="item.attype !== null || item.endtime !== null" color="warning" @click="handleStudentAction(item, 'ev_ill', 'ill', 'isIll')">{{ $t('dashboard.unwell') }}</v-btn>
                    </div>
                </template>
                <!-- ILL -->

                <!-- UNEXCUSED -->
                <template v-slot:header.unexc="{ header }">
                {{ $t('dashboard.unExcused') }}
                </template>
                <template v-slot:item.unexc="{ item }">
                    <div v-if="item.attype === 'u'" class="blue--text font-weight-bold">{{ $t('dashboard.unexcusedOff') }}</div>
                    <div v-else>
                        <v-btn x-small :loading="item.isUnexcused" :disabled="item.attype !== null || item.endtime !== null" color="primary" @click="handleStudentAction(item, 'ev_absence_unexcused','unexcused', 'isUnexcused')">{{ $t('dashboard.unexcused') }}</v-btn>
                    </div>
                </template>
                <!-- UNEXCUSED -->

                <!-- SETTINGS -->
                <template v-slot:header.settings="{ header }">
                {{ $t('dashboard.settings') }}
                </template>
                <template v-slot:item.settings="{ item }">
                    <div class="d-flex">
                        <v-tooltip color="error" left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="error" class="mr-1" v-bind="attrs" v-on="on" @click="resetStudent(item)" style="cursor: pointer;">cancel</v-icon>
                            </template>
                            <span>{{ $t('dashboard.reset') }}</span>
                        </v-tooltip>
                        <v-tooltip color="info" left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="ml-2" :color="item.notes ? 'info' : 'lightgray'" v-bind="attrs" v-on="on" @click="() => fsModal(item)" style="cursor: pointer;">info</v-icon>
                            </template>
                            <span>{{ $t('dashboard.info') }}</span>
                        </v-tooltip>
                    </div>
                </template>
                <!-- SETTINGS -->
            </v-data-table>
            <donut v-if="$vuetify.breakpoint.xs" class="d-sm-none d-md-block d-lg-block"/>
        </v-col>
        <!-- Second Column -->
        <fullscreenmodal ref="fsmodal"/>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import * as localForage from 'localforage';
import { dayInfo } from "../Utility/Helpers";

export default {
    name: 'Maintable',
    components: { 
        Usercard: () => import('../components/Usercard'), 
        Informationcard: () => import('../components/Informationcard'), 
        Classinfo: () => import('../components/Classinfo'), 
        Donut: () => import('../components/Charts/Donut/ChartBar'), 
        Fullscreenmodal: () => import('../components/Fullscreenmodal'),
    },
    data: () => ({
        tableSearching: false,
        showFullScreenModal: false,
        tableLoadingText: 'PLEASE WAIT LOADING YOUR DATA FROM TRYTON',
        resetObject: {
        "present": false,
        "ill": false,
        "unexc": false,
        "loggedIn": false,
        "loggedOut": false,
        "loggedInAt": "",
        "loggedOutAt": "" 
        },
        menu: ['Gender', 'Age', 'Settings'],
        show: ['Name', 'Log In', 'Log Out', 'Unwell', 'Un-Excused', 'Settings'],
        search:'',
        headers: [
          { text: 'Name', align: 'start', value: 'name' },
          //   { text: 'First Name', value: 'firstname', },
        //   { text: 'Last Name', value: 'lastname', },
          { text: 'Gender', value: 'gender', sortable: false },
          { text: 'Age', value: 'age', sortable: false },
          { text: 'Log In', value: 'loggedIn', sortable: false },
          { text: 'Log Out', value: 'loggedOut', sortable: false },
          { text: 'Unwell', value: 'ill', sortable: false },
          { text: 'Un-Excused', value: 'unexc', sortable: false },
          { text: 'Settings', value: 'settings', sortable: false},
        ],
    }),
    computed: {
        ...mapGetters({
            students: 'dashboardOnline/getStudents',
            presentStudents : 'dashboardOnline/presentStudents',
            absentStudents: 'dashboardOnline/absentStudents',
            illStudents: 'dashboardOnline/illStudents',
            unExcusedStudents: 'dashboardOnline/unExcusedStudents',
            loggedOutStudents: 'dashboardOnline/loggedOutStudents',
            isLoading: 'dashboardOnline/getLoadingStatus',
            loggingIn: 'dashboardOnline/getLoggingInStatus',
            isOnline: 'dashboardOnline/getNetworkStatus',
            offlineData: 'dashboardOnline/getOfflineDbStudents',
        }),
        selectedHeaders() {
            return this.headers.filter((item) => this.show.includes(item.text));
        }
    },
    methods: {
        // DEBOUNCE SEARCH INPUT ON TABLE
        debounceInput(val) {
            this.tableSearching = true;
            setTimeout(() => { 
                this.search = val;
                this.tableSearching = false;
            } , 500);
        },
        
        // SINGLE FUNCTION
        handleStudentAction(student, btn_name, type, key) {
            const choice = {};
            choice[key] = true;
            if (this.isOnline) {
                this.$store.dispatch('dashboardOffline/updateIndexedDb', { student, btn_name, type, key });
                this.$store.commit('dashboardOnline/handleAttendance', { data: { ...student, ...choice }, type: type} );
                this.$store.dispatch('dashboardOnline/handleAction', { id: student.id, btn_name }).then((res) => {
                    this.$store.dispatch('dashboardOnline/updateDataForStudent', { id: [student.id] });
                });
            } else {
                this.$store.commit('dashboardOnline/handleAttendance', { data: { ...student, ...choice }, type: type} );
                this.$store.dispatch('dashboardOffline/updateIndexedDb', { student, btn_name, type, key })
                .then((res) => {
                    const obj = {
                        data: {
                            result: [ res ]
                        }
                    }
                    this.$store.commit('dashboardOnline/updateStudentById', { resp: obj , id: [ obj.data.result[0].id ] });
                    this.$store.dispatch('dashboardOffline/addOfflineStudentIds', { student, btn_name, timingInfo: dayInfo() });
                });
            }
        },

        // RESET METHOD
        resetStudent(student) {
            this.isOnline ? this.onlineReset(student) : this.offlineReset(student);
        },

        // offline reset method
        offlineReset(student) {
            this.$store.commit('dashboardOnline/startLoading');
            this.$store.dispatch('dashboardOffline/resetTemplate', { student })
            .then((result) => {
                const obj = {
                    data: {
                        result: [ result ]
                    }
                }
                this.$store.dispatch('dashboardOffline/updateIndexedDb', { student: result, btn_name: obj.data.result[0].btn_name });
                this.$store.dispatch('dashboardOffline/addOfflineStudentIds', { student, btn_name: obj.data.result[0].btn_name, timingInfo: dayInfo() });
                this.$store.commit('dashboardOnline/updateStudentById', { resp: obj , id: [ obj.data.result[0].id ] });
                this.$store.commit('dashboardOnline/stopLoading');
            })
        },

        // Online reset method
        onlineReset(student) {
            this.$store.commit('dashboardOnline/startLoading');
            this.$store.dispatch('dashboardOnline/resetStudent', { id: student.id }).then((res) => {
                this.$store.dispatch('dashboardOnline/updateDataForStudent', { id: [student.id] });
            });
        },

        // GET STUDENT METHOD
        getStudents() {
            this.$store.commit('dashboardOnline/startLoading');
            this.$store.dispatch('dashboardOnline/getAllStudentIds').then((resp) => {
                this.$store.dispatch('dashboardOnline/getAllStudents', { ids: resp.data.result })
            });
        },

        // Full screen modal 
        fsModal(item) {
            this.$refs.fsmodal.toggleDialog(item);
        },

        // Decide Status
        getDataBasedOnNetwork() {
        if (this.isOnline) {
            if (this.students.length === 0) {
                this.$store.commit('dashboardOnline/startLoading');
                this.sendOfflineDataToServer();
            }
        } else {
            this.$store.dispatch('dashboardOffline/setOfflineIdsArray');
            this.$store.commit('dashboardOnline/startLoading');
            localForage.getItem('students')
            .then((res) => {
                this.$store.commit('dashboardOnline/setStudents', { data: { result: res }});
            })
            .catch((err) => {
                this.getStudents();
            });
          }
        },

        // Send offline data to server
        sendOfflineDataToServer() {
            localForage.getItem('ids').then((res) => {
                if (res) {
                  this.$store.dispatch('dashboardOffline/sendOfflineDataToServer')
                   .then((res) => { 
                        localForage.removeItem('ids');
                        this.getStudents();
                   });
                } else {
                    this.getStudents();
                }
            });
        }
    },
    mounted() {
        this.getDataBasedOnNetwork();
    }
}

</script>
